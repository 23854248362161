import React from 'react'
import Layout from '../../components/Layout'


const PodcastPage = () => (
  <Layout>
    <div className="row main-content columns" style={{float: 'inherit'}}>
        <section>
          <h1>STIPCast</h1>
          <div>
            <p>De STIPCast is de maandelijkse podcast van STIP Delft. In deze podcast praten Twan de Nijs en Marcel Harinck elke maand met een gast over echt Delftse onderwerpen. Ook wordt er gepraat over wat de gemeenteraadsfractie van STIP bezighoudt.</p>
            <p>Hieronder kan je de STIPCast direct luisteren. Ook is hij te beluisteren op <a href="https://open.spotify.com/show/2ktpZJIM75uvX4MrYTwtzK">Spotify</a>, <a href="https://podcasts.apple.com/nl/podcast/stipcast/id1480709552">iTunes</a> of je eigen podcast applicatie!</p>
            <br/>
            <iframe title="STIPCast" id="multi_iframe" src="https://www.podbean.com/media/player/multi?playlist=http%3A%2F%2Fplaylist.podbean.com%2F6084305%2Fplaylist_multi.xml&vjs=1&kdsowie31j4k1jlf913=3731a546467f3703637fa5fc1d0ad10a12fd7bba&size=430&skin=7&episode_list_bg=%23ffffff&bg_left=%23000000&bg_mid=%23000000&bg_right=%23000000&podcast_title_color=%23ffe100&episode_title_color=%23ffffff&auto=0&download=1&show_playlist_recent_number=10&pbad=1" scrolling="no" allowfullscreen="" width="80%" height="432" frameborder="0"></iframe>
          </div>
        </section>
    </div>
  </Layout>
)

export default PodcastPage
